/**
 * Copy this file to settings.js and re-deploy
 *
 * Pay attention to http https in the urls
 * Also gotta re-deploy when this is changed.
 */

const settings = {
	dburl        : 'https://igl.telegauge.com/telegauge', // location of mongo server
	posturl      : 'https://igl.telegauge.com', // base url that handles all POST
	name         : 'IGL:Ferry', // intallation name
	shortname: 'IGL',
	dburi        : 'igl.telegauge.com', // might not be needed
	enable_cron: false,
        twilio: {
                sid: 'AC731f9bf0c7acaadd70030f234d2955f7',
                token: '308a4605c92f17c96478969b4d1c41e4',
                phone: '+19044252778'
        },

	dbname       : 'telegauge', // also might not be needed
	login: 'magic',
	logo_url:'/img/Logo_white.png',
	poller_suffix: false, // if multiple Telegauges are running, you need a suffix
	db_port      : false, // alternate port, other than 3500
	basename     : false, // alternate base dir, other than /opt/telegauge
	pollers      : [ // contains a list of pollers that PM2 should start`
		'DB',
		'machine'
	],
//** Enable this for SMS messaging.
// twilio: {
// 	sid: 'AC731f9bf0c7acaadd70030f234d2955f7',
// 	token: '308a4605c92f17c96478969b4d1c41e4',
// 	phone: '+19044252778'
// }
};

module.exports = settings;
